document.addEventListener('DOMContentLoaded', () => {
  const form = document.getElementById('notification-form');
  const notificationSuccess = document.getElementById('notification');
  let timer;
  form.addEventListener('submit', (e) => {
    clearTimeout(timer);
    notificationSuccess.classList.remove('open');
    e.preventDefault();
    setTimeout(() => {
      notificationSuccess.classList.add('open');
    }, 100);
    form.reset();
    form.blur();
    timer = setTimeout(() => {
      notificationSuccess.classList.remove('open');
    }, 5000);
  });
});
